
      @use 'variables' as var;

      $inner-container-width: 350px;
      $inner-container-min-height: 250px;
      $radius-outer: var.$base-radius * 5;
      $radius-inner: var.$base-radius;

      sb-project-selector {
        max-width: 100%;
      }

      .project-selector-menu {
        margin-top: var.$base-spacer * 3;
        margin-right: -10px;
        border-radius: 30px;
      }

      .project-selector {
        display: grid;
        grid-template-rows: auto 1fr;
        width: $inner-container-width;
        min-height: $inner-container-min-height;
        padding: 2px 10px;
        @apply gap-0.5;

        .selected-project {
          background-color: #fff;
          border-radius: $radius-outer $radius-outer $radius-inner $radius-inner;
          padding: 24px 24px 20px;
          overflow: hidden;
        }

        .project-list {
          height: 100%;
          background-color: #fff;
          border-radius: $radius-inner $radius-inner $radius-outer $radius-outer;
          padding-top: 20px;
          overflow: hidden;
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          /* TODO(mdc-migration): The following rule targets internal classes of select that may no longer apply for the MDC version. */
          mat-selection-list {
            &:not(.add-project) {
              max-height: 195px;
              overflow-y: auto;
            }

            padding: 0;
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            /* TODO(mdc-migration): The following rule targets internal classes of list that may no longer apply for the MDC version. */
            mat-list-item {
              padding: 8px 24px;
            }
          }

          .add-project-container {
            .icon-container {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 40px;
              height: 40px;
              border-radius: 50%;
              border: 2px dashed var.$text-light;
            }
          }
        }
      }
    