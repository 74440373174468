
      @use 'variables' as var;
      @use 'mixins';

      @mixin status-base() {
        --mdc-list-list-item-hover-leading-icon-color: #{var.$nav-item-color};
        --mdc-list-list-item-hover-label-text-color: #{var.$nav-item-color};
        background-color: var.$nav-item-background-color-active;

        &.active {
          --mdc-list-list-item-hover-leading-icon-color: #{var.$nav-item-color-active};
          --mdc-list-list-item-hover-label-text-color: #{var.$nav-item-color-active};
        }
      }

      .dark-theme mat-toolbar.mat-toolbar {
        background-color: transparent;
      }

      sb-app-topbar mat-toolbar.mat-toolbar {
        background-color: transparent;
        position: relative;
        z-index: 99;

        mat-toolbar-row {
          justify-content: space-between;
          gap: 40px;
          height: var.$topbar-height;
          padding: 0 var.$nav-list-padding-x;

          .mat-mdc-list-item {
            @include mixins.nav-list-item();
            max-width: var.$nav-item-min-width;
          }

          .app-topbar-brand {
            display: block;
            margin-left: var.$app-topbar-brand-margin-left;
            text-decoration: none;

            @include mixins.media-breakpoint-up(md) {
              margin-left: var.$app-topbar-brand-margin-left-md;
            }

            img {
              display: flex;
              max-width: 100%;
              max-height: 100%;
              width: auto;
              height: var.$app-topbar-brand-img-height;
            }
          }
        }
      }
    