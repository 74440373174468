@use 'variables';

@mixin nav-list-item() {
  --mdc-list-list-item-leading-icon-color: #{variables.$nav-item-color};
  --mdc-list-list-item-label-text-color: #{variables.$nav-item-color};
  --mdc-list-list-item-focus-label-text-color: #{variables.$nav-item-color};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  min-width: variables.$nav-item-min-width;
  height: variables.$nav-item-height !important;
  padding: variables.$nav-item-padding-y variables.$nav-item-padding-x;
  border-radius: variables.$nav-item-radius;
  cursor: pointer;

  &.right {
    flex-direction: row-reverse;
  }

  mat-icon.mat-mdc-list-item-icon {
    z-index: 1;
    margin: 0;

    svg {
      height: variables.$nav-item-icon-size;
      width: variables.$nav-item-icon-size;
    }

    &.force-active,
    &.active {
      display: none;
    }
  }

  // status overlay
  &:not(.disable-click) {

    &:hover::before,
    &:focus::before,
    &::before {
      display: block;
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      border: none;
      border-radius: variables.$nav-item-radius;
      background-color: variables.$item-status-overlay-background-color;
      opacity: 0;
      transition: opacity 0.1s;
    }

    &:hover,
    &:focus {
      --mdc-list-list-item-hover-leading-icon-color: #{variables.$nav-item-color};
      --mdc-list-list-item-hover-label-text-color: #{variables.$nav-item-color};
      background-color: variables.$nav-item-background-color-active;

      &.force-active,
      &.active {
        --mdc-list-list-item-hover-leading-icon-color: #{variables.$nav-item-color-active};
        --mdc-list-list-item-hover-label-text-color: #{variables.$nav-item-color-active};
      }
    }

    // Hover status
    &:hover {
      &::before {
        opacity: variables.$nav-item-overlay-opacity-hover;
      }
    }

    // Focus status
    &:hover:focus {
      &::before {
        opacity: variables.$nav-item-overlay-opacity-focus;
      }
    }

    &:focus {
      &::before {
        opacity: 0;
      }
    }
  }

  // Active status
  &.force-active,
  &.active {
    --mdc-list-list-item-leading-icon-color: #{variables.$nav-item-color-active};
    --mdc-list-list-item-label-text-color: #{variables.$nav-item-color-active};
    --mdc-list-list-item-focus-label-text-color: #{variables.$nav-item-color-active};
    background-color: variables.$nav-item-background-color-active;

    .mat-mdc-list-item-icon.default {
      display: none;
    }

    .mat-mdc-list-item-icon.active {
      display: inline-flex;
    }
  }

  .mdc-list-item__primary-text {
    padding-left: variables.$nav-item-label-padding-left;
  }

  &.right {
    .mdc-list-item__primary-text {
      padding-left: 0;
      padding-right: variables.$nav-item-label-padding-left;
    }
  }

  &.disable-click {
    cursor: default;
  }
}
